
import Vue from 'vue';
import { createPlaceholderImage } from '@/_helpers/misc_helper';

import RibbonBanner from '@/components/general/utils/RibbonBanner.vue';

export default Vue.extend({
	name: 'LocationList',

	components: { RibbonBanner },

	props: {
		locations: {
			type: Array,
			required: true
		}
	},

	computed: {
		isMobile(): boolean {
			return this.$vuetify.breakpoint.smAndDown;
		},

		placeholderImage(): string {
			if (process.server) {
				return ''
			}
			return createPlaceholderImage({
				width: 400,
				text: this.$t('truck.pictures.none') as string
			});
		}
	}
});
