
import Vue from 'vue';

export default Vue.extend({
	name: 'SectionHeading',

	props: {
		title: {
			type: String,
			required: true
		},
		subtitle: {
			type: String,
			default: null
		}
	}
});
