
import Vue from 'vue';
import type { MetaInfo } from 'vue-meta';
import { Truck } from '~/types/truck';
import { routesNames } from '@/router';

import SectionHeading from '@/components/general/utils/SectionHeading.vue';
import MapTruckCarouselNew from '@/components/map/MapTruckCarouselNew.vue';
import LocationList from '@/components/home/LocationList.vue';

import MISC_DATA from '@/store/modules/MiscDataModule';
import UTILS from '@/store/modules/UtilityModule';
import TRUCKS from '@/store/modules/TruckModule';

const LandingPage = Vue.extend({
	name: 'LandingPage',

	components: {
		SectionHeading,
		LocationList,
		MapTruckCarouselNew
	},

	middleware: 'fetchLPData',

	data() {
		return {
			is_loaded: false,
			trucks: [] as Truck[]
		};
	},

	head(): MetaInfo {
		return {
			link: [this.canonicalLink]
		};
	},

	computed: {
		UTILS: () => UTILS,
		MISC_DATA: () => MISC_DATA,
		TRUCKS: () => TRUCKS,

		landingPages(): any[] {
			return this.MISC_DATA.landing_pages;
		},

		LPData(): any {
			return this.MISC_DATA.current_LP_data;
		},

		city(): string {
			return this.LPData.name;
		},

		LPTrucks(): string[] {
			return this.LPData.trucks;
		},

		canonicalLink(): any {
			let path = ''
			if (this.LPData && this.LPData.url) {
				path = this.LPData.url;
			} else {
				path = this.$route.path;
			}
			return {
				hid: 'page-canonical',
				rel: 'canonical',
				href: this.$config[`url_${this.$getDomain()}`] + path
			};
		},
	},

	async mounted() {
		if (this.LPData) {
			this.is_loaded = true;
		} else {
			await this.getLandingPageData();
		}

		if (this.LPTrucks && this.LPTrucks.length > 0) {
			this.buildTruckList();
		} else {
			// No truck on this LP -> Pop a message no that customer know
			(this as any).$snackbar.showMessage({
				content: this.$t('msg.no_trucks_here'),
				position: 'center',
				time: 6000
			});
		}
	},

	methods: {
		goToMap() {
			this.$vuetify.goTo(0, { duration: 320, easing: 'easeInOutCubic' });
		},

		async buildTruckList(): Promise<void> {
			if (!this.LPTrucks || this.LPTrucks.length < 1) {
				return;
			}

			const list = [] as Truck[];

			for (const t in this.LPTrucks) {
				const truck = await this.TRUCKS.GET_TRUCK_DATA({ truck_id: this.LPTrucks[t] });
				if (truck instanceof Truck) {
					list.push(truck);
				}

				if (list.length >= 10) {
					break;
				}
			}

			this.trucks = list;
		},

		async getLandingPageData() {
			const id = this.landingPages.filter((lp) => lp.url === this.$route.path)[0] as any;
			const domain = this.$getDomain();

			if (id) {
				await this.MISC_DATA.GET_LANDING_PAGE({ domain: domain, id: id.id });
			} else {
				this.$router.push({ name: routesNames.home });
			}
			this.is_loaded = true;
		}
	}
});

export type LPRef = InstanceType<typeof LandingPage>;
export default LandingPage;
